import { IconButton } from "@chakra-ui/core";
import React from "react";
import { MdDelete} from "react-icons/md";
// import db from "../lib/firebase";

const Delete = ({ posts, post, refreshPage }) => {

    const handleDelete = async () => {
    // Do calculation to save the vote.
        let id = post.id;

        const url = "https://social-media-app.divyachalla95.workers.dev/posts";
        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'content-type': 'application/json'
            },
            body: JSON.stringify({posts: posts.filter((item)=>{
            return item.id != id
            })})
        };
        try {
            const response = fetch(url, requestOptions);
            console.log(response);
        } catch (error) {
            console.log("error", error);
        }
        refreshPage();
  };

  return (
    <>
        <IconButton
          size="md"
          colorScheme="red"
          aria-label="Delete"
          icon={<MdDelete />}
          onClick={() => handleDelete()}
        />
    </>
  );
};

export default Delete;