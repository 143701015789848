import {
    Button,
    FormControl,
    FormLabel,
    Textarea,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    HStack,
    useDisclosure,
  } from "@chakra-ui/core";
  import React, { useState, useEffect } from "react";
//   import db from "../lib/firebase";
  
  const AddNewPost = ({posts, refreshPage}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [URL, setURL] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [isSaving, setSaving] = useState(false);

    const handleSubmit = () => {
      const date = new Date();
      const url = "https://social-media-app.divyachalla95.workers.dev/posts";
      const requestOptions = {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Content-type': 'application/json'
          },
          body: JSON.stringify({posts: posts.concat([{
              id: Math.floor(Math.random() * Date.now()),
              title,
              body,
              URL,
              imageURL: imageURL,
              upVotesCount: 0,
              downVotesCount: 0,
              createdAt: date.toUTCString(),
              updatedAt: date.toUTCString(),
            }])})
      };
        try {
          const response = fetch(url, requestOptions);
          console.log(response);
        } catch (error) {
          console.log("error", error);
        }
        onClose();
        setTitle("");
        setBody("");
        setURL("");
        setImageURL("");
        refreshPage();
    }
  
    return (
      <>
        <Button onClick={onOpen} colorScheme="blue">
          Add new post
        </Button>
  
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>Add new post</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl id="post-title">
                  <FormLabel>Post title</FormLabel>
                  <Textarea
                    type="post-title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalBody>
                <FormControl id="post-body">
                  <FormLabel>Post body</FormLabel>
                  <Textarea
                    type="post-body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalBody>
                <FormControl id="post-url">
                  <FormLabel>URL</FormLabel>
                  <Textarea
                    type="post-url"
                    value={URL}
                    onChange={(e) => setURL(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalBody>
                <FormControl id="post-url">
                  <FormLabel>Image URL</FormLabel>
                  <Textarea
                    type="image-url"
                    value={imageURL}
                    onChange={(e) => setImageURL(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <HStack spacing={4}>
                  <Button onClick={onClose}>Close</Button>
                  <Button
                    onClick={handleSubmit}
                    colorScheme="blue"
                    disabled={!title.trim()}
                    isLoading={isSaving}
                  >
                    Save
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </>
    );
  };
  
  export default AddNewPost;