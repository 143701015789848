import { Box, HStack, Text, Link, Heading, Container, Flex, VStack, StackDivider } from "@chakra-ui/core";
import React from "react";
import VoteButtons from "./vote-buttons";
import Comment from "./comment";
import Delete from "./delete";

const Post = ({ posts, post, refreshPage }) => {
  console.log(post);
  return (
    <HStack key={post.id} w="100%" alignItems="flex-start">
      <VoteButtons post={post} posts={posts} refreshPage={refreshPage} />
      <Box bg="gray.100" p={4} rounded="md" w="100%">
        <Flex justifyContent="flex-end" w="100%" position="sticky" top={0}>
          <Heading flex='auto'> {post.title} </Heading>
          <Delete post={post} posts={posts} refreshPage={refreshPage} />
        </Flex>
        <Box bg="gray.100" p={4} rounded="md" w="100%">
            <Text>{post.body}</Text>
            {post.URL? <Text>URL: <Link href={post.URL}>{post.URL}</Link></Text> : null}
        </Box>
        {post.imageURL ?
        <Container maxW="lg" centerContent>
            <img src={post.imageURL} width="500px"></img>
        </Container>: null}
        <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
        >
        <Flex justifyContent="flex-end" w="100%" position="sticky" top={0}>
            <Comment post={post} posts={posts} refreshPage={refreshPage} />
        </Flex>
        {post.comment && post.comment.map((comm) => {
            return (
                <Box bg="blue.100" p={2} rounded="md" w="100%">
                    {comm}
                </Box>
            )
        })}
        </VStack>
      </Box>
    </HStack>
  );
};

export default Post;